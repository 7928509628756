<template>
  <div class="the_sidebar bg-white pt-6 pb-6 fixed">
    <div class="flex items-center justify-center pl-6 pr-6">
      <icon
        :data="icons.logo"
        height="60"
        width="60"
        class="mr-2"
        original
      />
      <div class="agence h-60 flex items-center pl-5 pr-4 bg-f3 ml-3 rounded-11 w-full">
        <div class="w-full mt-1">
          <div class="flex items-center">
            <icon
              :data="icons.agence"
              height="15"
              width="15"
              class="mr-2"
              original
            />
            <div class="text-c12 text-left text-lite-violet">
              Service :
            </div>
          </div>

          <div class="text-c24 font-c6 text-left text-dark-violet">
           Technique
          </div>
        </div>
      </div>
    </div>

    <div class="mt-6">
      <divider
        color="#EAEAEF"
        height="1.27px"
      />
    </div>

    <div class="defilOption pb-20">
<!--      <div class="mt-6 pl-6 pr-6">-->
<!--        <div class="text-left text-c13 text-lite-violet">-->
<!--          ACCUEIL-->
<!--        </div>-->

<!--        <div class="mt-5">-->
<!--          <menu-element-->
<!--            menu="Tableau de bord"-->
<!--            :icon="icons.dashboard"-->
<!--            path="dashboard"-->
<!--          />-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="mt-6 pr-6">-->
<!--        <divider-->
<!--          color="#EAEAEF"-->
<!--          height="1.27px"-->
<!--        />-->
<!--      </div>-->

      <div class="mt-6 pl-6 pr-6">
        <div class="text-left text-c13 text-lite-violet">
          TECHNIQUE
        </div>

        <div class="pt-2">
          <element-special
            menu="Validations"
            :icon="icons.vente"
            path="home"
            :all-item="vente"
          />
        </div>

<!--        <div class="pt-2">-->
<!--          <menu-element-->
<!--              menu="Montage"-->
<!--              :icon="icons.montage"-->
<!--              path="montage"-->
<!--          />-->
<!--        </div>-->

<!--        <div class="pt-2">-->
<!--          <menu-element-->
<!--            menu="Livraisons"-->
<!--            :icon="icons.livraison"-->
<!--            path="livraison"-->
<!--          />-->
<!--        </div>-->

      </div>

<!--      <div class="mt-10 pr-6">-->
<!--        <divider-->
<!--          color="#EAEAEF"-->
<!--          height="1.27px"-->
<!--        />-->
<!--      </div>-->

<!--      <div class="mt-3 pl-6 pr-6">-->
<!--        <div class="mt-4">-->
<!--          <menu-element-->
<!--            menu="SAV"-->
<!--            :icon="icons.savo"-->
<!--            path="sav"-->
<!--          />-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="mt-4 pr-6">-->
<!--        <divider-->
<!--          color="#EAEAEF"-->
<!--          height="1.27px"-->
<!--        />-->
<!--      </div>-->

<!--      <div class="mt-4 pl-6 pr-6">-->
<!--        <div class="mt-2">-->
<!--          <menu-element-->
<!--            menu="Agenda"-->
<!--            :icon="icons.agenda"-->
<!--            path="agenda"-->
<!--          />-->
<!--        </div>-->
<!--      </div>-->

    </div>
  </div>
</template>

<script>
// import menuElement from './side-menu-element'
import elementSpecial from './side-menu-special'
import agence from '../../assets/icons/agenceGray.svg'
import dashboard from '../../assets/icons/dashboard.svg'
import logo from '../../assets/icons/logo.svg'
import user from '../../assets/icons/userS.svg'
import vente from '../../assets/icons/vente.svg'
import validation from '../../assets/icons/validation.svg'
import livraison from '../../assets/icons/livraison.svg'
import montage from '../../assets/icons/montage.svg'
import savo from '../../assets/icons/sav.svg'
import agenda from '../../assets/icons/agenda.svg'
import article from '../../assets/icons/article.svg'
import divider from './add/divider'

export default {
  name: 'TheSidebar',
  components: {
    // menuElement,
    divider,
    elementSpecial
  },

  data () {
    return {
      // vente: ['commandes verres', 'Commande spéciales', 'Réceptions des verres'],
      vente: ['commandes verres'],
      icons: {
        agence,
        logo,
        dashboard,
        user,
        vente,
        validation,
        livraison,
        montage,
        savo,
        agenda,
        article
      },
      agence: ''
    }
  },

  computed: {

  },

  mounted(){
    // this.agence = this.$store.getters.trackAgence.toLowerCase().replace('agence', '')
    // if (this.agence.substr(0, 1) === ' '){
    //   this.agence = this.agence.substr(1, this.agence.length)
    // }
  },

  methods: {
  }
}
</script>
<style lang="scss" scoped>
@import '../../assets/styles/sass/variables';
.the_sidebar{
  height: 100vh;
  border-right: 1px solid #EAEAEF;
}
.defilOption{
  height: 100%;
  max-height: 90%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.defilOption::-webkit-scrollbar {
  display: none;
}
.svg-icon {
  fill: white;
}
</style>
